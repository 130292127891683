import React, { useState, useEffect } from "react";
import {
  Typography,
  Input,
  Button,
  Radio,
  Space,
  Row,
  Col,
  Checkbox,
  Tooltip,
  message,
  Modal,
  Popover,
  Spin,
  Collapse,
  Select,
  notification,
} from "antd";
import {
  QuestionCircleFilled,
  CloseOutlined,
  MinusSquareFilled,
  PlusSquareFilled,
  InfoCircleFilled,
} from "@ant-design/icons";
import {
  STATE_DISPLAY_TYPE,
  STATES,
  STATES_BY_POPULATION,
  STATES_BY_TRANSACTION,
  MAINLAND_STATES,
  EXCLUSION_LIST,
  PRODUCT_JSON,
} from "constants/Constants";
import connectionInstance from "api_services/connection";
import Components from "components";
import { Line } from "react-chartjs-2";
import { SelectedProduct } from "api_services/localstorage";
import moment from "moment";
import { useAuthContext } from "../../contexts/AuthContext";
import style from "./dashboard.module.scss";
import DataAnalysisInput from "./DataAnalysisInput";

const { Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

export const getThresholdData = async function (
  triggerRequestId,
  setshowLoading
) {
  try {
    const triggerDataResponse = await connectionInstance.get(
      `${process.env.REACT_APP_API_URL}/probability/status/${triggerRequestId}`
    );
    if (triggerDataResponse && triggerDataResponse.data) {
      return triggerDataResponse.data;
    }
    return false;
  } catch (error) {
    setshowLoading(false);
    if (error.response.status !== 401) {
      if (error.response && error.response.data) {
        message.error(
          (typeof error.response.data.error_string === "string" &&
            error.response.data.error_string) ||
            (typeof error.response.data.message === "string" &&
              error.response.data.message) ||
            (typeof error.response.data.errorMsg === "string" &&
              error.response.data.errorMsg) ||
            (typeof error.response.data.error_message === "string" &&
              error.response.data.error_message) ||
            "Sorry! We are unable to fetch probable trigger analysis now. Please try after some time."
        );
      } else {
        message.error(
          "Sorry! We are unable to fetch probable trigger analysis now. Please try after some time."
        );
      }
    }
    return false;
  }
};

const Dashboard = () => {
  const userContext = useAuthContext();
  const { user } = userContext;
  const selectedProduct = SelectedProduct.getProduct();
  const [thresholdValue, setthresholdValue] = useState();
  const [thresholdRangeError, setthresholdRangeError] = useState(false);
  const [disableRunButton, setdisableRunButton] = useState(false);
  const [probabilityValue, setprobabilityValue] = useState(0);
  const [expectedPayoutValue, setexpectedPayoutValue] = useState(0);
  const [radioButtonValue, setradioButtonValue] = useState("all");
  const [showStateSection, setshowStateSection] = useState(false);
  const [showSelectedStateLabel, setshowSelectedStateLabel] = useState(false);
  const [selectedStates, setselectedStates] = useState("");
  const [stateListArray, setstateListArray] = useState(STATES);
  const [countSelectedState, setcountSelectedState] = useState(51);
  const [disableDownload, setdisableDownload] = useState(false);
  const [downloadURL, setdownloadURL] = useState();
  const [showProbabilityTrigger, setshowProbabilityTrigger] = useState(false);
  const [showLoading, setshowLoading] = useState(false);
  const [showSpinner, setshowSpinner] = useState(false);
  const [showButtonSpinner, setshowButtonSpinner] = useState({
    quickAnalysis: false,
    deepAnalysis: false,
  });
  const [intervalData, setintervalData] = useState({
    intervalValue: null,
    intervalDuration: 3000,
    timeoutValue: null,
  });
  const [disableWhileLoading, setdisableWhileLoading] = useState(false);
  const [exclusionStatus, setexclusionStatus] = useState(true);
  const [analysisType, setanalysisType] = useState(
    selectedProduct == "BudgetLock" ? "city" : "square"
  );
  const [
    frequencyDistributionModalVisible,
    setfrequencyDistributionModalVisible,
  ] = useState(false);
  const [confirmationModalVisible, setconfirmationModalVisible] =
    useState(null);
  const [totalRisk, settotalRisk] = useState("");
  const [reinsurerRisk, setreinsurerRisk] = useState("");
  const [probabilityForPML, setProbabilityForPML] = useState("");
  const [averagePayout, setaveragePayout] = useState("");
  const [premiumPerOutlock, setpremiumPerOutlock] = useState("");
  const [percentageOfRisk, setpercentageOfRisk] = useState("100");
  const [maxSale, setmaxSale] = useState("");
  const [lowCapFactor, setlowCapFactor] = useState("");
  const [highCapFactor, sethighCapFactor] = useState("");
  const [numberOfHotZones, setnumberOfHotZones] = useState("0");
  const [hotZonesTotalRisk, sethotZonesTotalRisk] = useState("0");
  const [disableFrequencyDistribution, setdisableFrequencyDistribution] =
    useState(true);
  const [triggerFileName, settriggerFileName] = useState(null);
  const [zipCodeModalVisible, setZipCodeModalVisible] = useState(false);
  const [popover, setpopover] = useState({
    totalRisk: false,
    reinsurerRisk: false,
    probabilityForPML: false,
    payout: false,
    premium: false,
    percentageOfRisk: false,
    maxSale: false,
    lowCapFactor: false,
    highCapFactor: false,
    hotZonesTotalRisk: false,
    numberOfHotZones: false,
    reset: false,
    run: false,
    deepAnalysis: false,
    quickAnalysis: false,
    downloadData: false,
    WHO: false,
    STW: false,
  });
  // const [alertMessage, setalertMessage] = useState(null);
  const [statsFile, setstatsFile] = useState(null);
  const [economicActivity, seteconomicActivity] = useState([]);
  const [squares, setsquares] = useState([]);
  const [exclusionCheckBox, setexclusionCheckBox] = useState({
    "WHO pandemic declaration": false,
    "State-wide lockdowns": true,
  });
  const [requestId, setrequestId] = useState(null);
  const [numberOfOutlockWorthySquare, setnumberOfOutlockWorthySquare] =
    useState(null);
  const [triggerDurationValue, settriggerDurationValue] = useState("2 months");
  const [activeExclusionPanel, setactiveExclusionPanel] = useState(1);
  const [lowerEconomicPercentile, setlowerEconomicPercentile] = useState(0);
  const [upperEconomicPercentile, setupperEconomicPercentile] = useState(100);
  const [triggerDrop, settriggerDrop] = useState(10);
  const [triggerDropError, settriggerDropError] = useState(false);
  const [policyNumberModalVisible, setPolicyNumberModalVisible] =
    useState(null);
  const [makeProductModalVisible, setMakeProductModalVisible] = useState(false);
  const [product, setProduct] = useState({
    name: "",
    internalName: "",
    desc: "",
  });
  const [pricingValue, setPricingValue] = useState("national");
  // const [validateTotalRisk, setvalidateTotalRisk] = useState(false);
  // const [api, contextHolder] = notification.useNotification();

  const openNotification = (desc) => {
    notification.error({
      message: "",
      description: desc,
      placement: "top",
      duration: 0,
      icon: <InfoCircleFilled style={{ color: "#ff4d4f", fontSize: "24px" }} />,
      // key: 'closeNotification'
    });
  };

  useEffect(() => {
    setselectedStates(null);
    let states = "";
    let selectedState = 0;
    stateListArray.map((item) => {
      item.states.map((state) => {
        if (state.status) {
          selectedState++;
          states += `${state.value},`;
        }
      });
    });
    states = states.substring(0, states.length - 1);
    if (radioButtonValue !== "user-preference") {
      setselectedStates(states);
      setshowProbabilityTrigger(false);
    } else {
      setselectedStates(null);
    }
    setcountSelectedState(selectedState);
    radioButtonValue !== "user-preference" &&
    states &&
    thresholdValue &&
    !thresholdRangeError &&
    triggerDrop &&
    !triggerDropError
      ? setdisableRunButton(false)
      : setdisableRunButton(true);
  }, [radioButtonValue, countSelectedState]);

  useEffect(() => {
    if (
      countSelectedState > 0 &&
      thresholdValue &&
      triggerDrop &&
      selectedStates &&
      !thresholdRangeError &&
      !triggerDropError
    ) {
      if (
        selectedProduct == "BudgetLock" &&
        !(lowerEconomicPercentile < upperEconomicPercentile)
      ) {
        setdisableRunButton(true);
      } else {
        setdisableRunButton(false);
      }
    } else {
      setdisableRunButton(true);
    }
  }, [
    countSelectedState,
    thresholdValue,
    triggerDrop,
    selectedStates,
    thresholdRangeError,
    triggerDropError,
    lowerEconomicPercentile,
    upperEconomicPercentile,
  ]);

  useEffect(() => {
    if (
      // (totalRisk || reinsurerRisk || selectedProduct == "LoanProtect") &&
      averagePayout &&
      premiumPerOutlock &&
      percentageOfRisk &&
      lowCapFactor &&
      highCapFactor &&
      triggerFileName &&
      statsFile &&
      maxSale &&
      !disableWhileLoading
    ) {
      if (
        analysisType == "square" &&
        (!hotZonesTotalRisk || !numberOfHotZones)
      ) {
        setdisableFrequencyDistribution(true);
      } else {
        setdisableFrequencyDistribution(false);
      }
    } else {
      setdisableFrequencyDistribution(true);
    }
  }, [
    // totalRisk,
    // reinsurerRisk,
    averagePayout,
    premiumPerOutlock,
    lowCapFactor,
    percentageOfRisk,
    highCapFactor,
    triggerFileName,
    statsFile,
    disableWhileLoading,
    hotZonesTotalRisk,
    numberOfHotZones,
    maxSale,
  ]);

  // useEffect(() => {
  //   if (
  //     maxSale &&
  //     averagePayout &&
  //     totalRisk &&
  //     Number(totalRisk) < Number(averagePayout) * Number(maxSale)
  //   ) {
  //     settotalRisk("");
  //     openNotification(
  //       "The 'Total risk cap' cannot be less than the product of the 'Payout if policy triggers' and 'How many policies sold'"
  //     );
  //   }
  // }, [validateTotalRisk]);

  const toolTipText =
    "For example, if you set 20 in this field then:\n\n(a) If the nation's economic activity " +
    "(as measured by the data we have) rose by 4% over the measurement period, then " +
    `an ${selectedProduct} in ` +
    `a particular ${
      analysisType == "square"
        ? " square"
        : analysisType == "zip"
        ? " zip code"
        : " city"
    } would be a candidate for a ` +
    `trigger if that ${
      analysisType == "square"
        ? " square"
        : analysisType == "zip"
        ? " zip code"
        : " city"
    } dropped by 16% or worse during ` +
    "the same period.\n\n(b) If the nation's economic activity dropped by 3% over the measurement period then " +
    `an ${selectedProduct} in a particular ${
      analysisType == "square"
        ? " square"
        : analysisType == "zip"
        ? " zip code"
        : " city"
    } would be a ` +
    `candidate for a trigger if that ${
      analysisType == "square"
        ? " square"
        : analysisType == "zip"
        ? " zip code"
        : " city"
    } dropped by 23% or ` +
    "worse during the same period.\n\n(c)...and so on.";

  const handleThresholdValueChange = (event) => {
    setthresholdValue(event.target.value);
    // setdisableDownload(true);
  };

  const handleTriggerDropValueChange = (event) => {
    settriggerDrop(event.target.value);
  };

  const handleDownloadButtonClick = async () => {
    window.location.href = downloadURL;
    setdisableDownload(true);
  };

  const handleResetButtonClick = () => {
    clearInterval(intervalData.intervalValue);
    clearTimeout(intervalData.timeoutValue);
    setintervalData((prev) => ({
      ...prev,
      intervalValue: null,
      timeoutValue: null,
    }));
    setthresholdValue();
    settriggerDrop();
    setshowStateSection(false);
    setshowProbabilityTrigger(false);
    setTimeout(() => {
      if (!showLoading) {
        setshowProbabilityTrigger(false);
      }
    }, 1000);
    STATES.map((item) => {
      item.states.map((state) => {
        state.status = true;
      });
    });
    setstateListArray([...STATES]);
    let selectedState = 0;
    STATES.map((item) => {
      item.states.map((state) => {
        if (state.status) {
          selectedState++;
        }
      });
    });
    setcountSelectedState(selectedState);
    setradioButtonValue("all");
    setdisableWhileLoading(false);

    setexclusionStatus(true);
    setdisableRunButton(true);
    setthresholdRangeError(false);
    setdisableDownload(false);
    setanalysisType(selectedProduct == "BudgetLock" ? "city" : "square");
    settriggerFileName(null);
    setstatsFile(null);
    setexclusionCheckBox((prev) => ({
      ...prev,
      "WHO pandemic declaration": false,
      "State-wide lockdowns": true,
    }));
    settriggerDurationValue("2 months");
    setactiveExclusionPanel(1);
    const blankArray = [];
    seteconomicActivity([...blankArray]);
    setsquares([...blankArray]);
    setlowerEconomicPercentile(0);
    setupperEconomicPercentile(100);
    settriggerDrop(10);
    settriggerDropError(false);
    settotalRisk("");
    setreinsurerRisk("");
    setProbabilityForPML("");
    setaveragePayout("");
    setpercentageOfRisk("100");
    setpremiumPerOutlock("");
    setmaxSale("");
    setlowCapFactor("");
    sethighCapFactor("");
    if (analysisType == "square") {
      setnumberOfHotZones("0");
      sethotZonesTotalRisk("0");
    } else {
      setnumberOfHotZones("0");
      sethotZonesTotalRisk("0");
    }
    setnumberOfOutlockWorthySquare(null);
  };

  const handleFrequencyDistribution = async (quickRun) => {
    try {
      setdisableWhileLoading(true);
      setdisableDownload(true);
      if (quickRun) {
        setshowButtonSpinner((prev) => ({
          ...prev,
          quickAnalysis: true,
        }));
      } else {
        setshowButtonSpinner((prev) => ({
          ...prev,
          deepAnalysis: true,
        }));
      }

      const requestParams = {
        userName: user.name,
        userEmail: user.email,
        triggerFile: triggerFileName,
        // userRiskInMillions: !(selectedProduct == "LoanProtect")
        //   ? totalRisk
        //   : averagePayout * maxSale,
        userRiskInMillions: totalRisk || 0,
        reinsurerRiskInMillions: reinsurerRisk || 0,
        probabilityForPML: probabilityForPML || 0,
        userRiskPercent: percentageOfRisk,
        meanPayout: averagePayout,
        numberOfOutlocks: maxSale,
        premiumPerOutlock,
        lowCap: lowCapFactor,
        highCap: highCapFactor,
        hotZoneCount: analysisType == "zip" ? 0 : Number(numberOfHotZones),
        maxRiskPerHotzoneInMillions:
          analysisType == "zip" ? 0 : Number(hotZonesTotalRisk),
        geoBasis: analysisType,
        pricingOption: pricingValue,
        statsFile,
        quickRun: quickRun ? "1" : "0",
        requestId,
        exclusionsList:
          Object.keys(exclusionCheckBox).filter(
            (k) => exclusionCheckBox[k] === true
          ).length > 0
            ? getExclusionList(false)
            : "None",
        product: selectedProduct,
      };
      const response = await connectionInstance.get(
        `${process.env.REACT_APP_API_URL}/probability/frequency_distribution`,
        {
          params: requestParams,
        }
      );
      if (response && response.data && response.data.processed) {
        if (quickRun) {
          setshowButtonSpinner((prev) => ({
            ...prev,
            quickAnalysis: false,
          }));
        } else {
          setshowButtonSpinner((prev) => ({
            ...prev,
            deepAnalysis: false,
          }));
        }
        setdisableWhileLoading(false);
        setdisableDownload(false);
        setconfirmationModalVisible(quickRun ? "quick" : "deep");
      }
    } catch (error) {
      if (quickRun) {
        setshowButtonSpinner((prev) => ({
          ...prev,
          quickAnalysis: false,
        }));
      } else {
        setshowButtonSpinner((prev) => ({
          ...prev,
          deepAnalysis: false,
        }));
      }
      setdisableDownload(false);
      setdisableWhileLoading(false);
      if (
        error &&
        error.response &&
        error.response.status &&
        error.response.status !== 401
      ) {
        if (error.response.data) {
          message.error(
            (typeof error.response.data.error_string === "string" &&
              error.response.data.error_string) ||
              (typeof error.response.data.message === "string" &&
                error.response.data.message) ||
              (typeof error.response.data.errorMsg === "string" &&
                error.response.data.errorMsg) ||
              (typeof error.response.data.error_message === "string" &&
                error.response.data.error_message) ||
              "Sorry! We are unable to record your request now. Please try after some time."
          );
        } else {
          message.error(
            "Sorry! We are unable to record your request now. Please try after some time."
          );
        }
      } else {
        message.error(
          "Sorry! We are unable to record your request now. Please try after some time."
        );
      }
    }
  };

  const handlePricingRadioButtonChange = (e) => {
    setPricingValue(e.target.value);
  };

  const handleAnalysisRadioButtonChange = (e) => {
    setradioButtonValue(e.target.value);

    setdisableDownload(true);

    if (e.target.value === "all") {
      STATES.map((item) => {
        item.states.map((state) => {
          state.status = true;
        });
      });
      setstateListArray([...STATES]);
    } else if (e.target.value === "mainland-states") {
      STATES.map((item) => {
        item.states.map((state) => {
          state.status = false;
          MAINLAND_STATES.map((popularState) => {
            if (state.value === popularState.value) {
              state.status = true;
            }
          });
        });
      });
      setstateListArray([...STATES]);
    } else if (e.target.value === "population") {
      STATES.map((item) => {
        item.states.map((state) => {
          state.status = false;
          STATES_BY_POPULATION.map((popularState) => {
            if (state.value === popularState.value) {
              state.status = true;
            }
          });
        });
      });
      setstateListArray([...STATES]);
    } else if (e.target.value === "transactions") {
      STATES.map((item) => {
        item.states.map((state) => {
          state.status = false;
          STATES_BY_TRANSACTION.map((popularState) => {
            if (state.value === popularState.value) {
              state.status = true;
            }
          });
        });
      });
      setstateListArray([...STATES]);
    } else if (e.target.value === "user-preference") {
      setshowStateSection(true);
      setdisableRunButton(true);
    }
  };

  const selectAllStates = (region, states) => {
    setradioButtonValue("user-preference");
    setdisableRunButton(true);
    if (region) {
      states.map((state) => {
        state.status = true;
      });
    } else {
      STATES.map((item) => {
        item.states.map((state) => {
          state.status = true;
        });
      });
    }
    setstateListArray([...STATES]);

    let selectedState = 0;
    STATES.map((item) => {
      item.states.map((state) => {
        if (state.status) {
          selectedState++;
        }
      });
    });
    setcountSelectedState(selectedState);
  };

  const clearAllStates = (region, states) => {
    setradioButtonValue("user-preference");
    setdisableRunButton(true);
    if (region) {
      states.map((state) => {
        state.status = false;
      });
    } else {
      STATES.map((item) => {
        item.states.map((state) => {
          state.status = false;
        });
      });
    }
    setstateListArray([...STATES]);

    let selectedState = 0;
    STATES.map((item) => {
      item.states.map((state) => {
        if (state.status) {
          selectedState++;
        }
      });
    });
    setcountSelectedState(selectedState);
  };

  const handleSaveSelectedState = () => {
    setselectedStates();
    let states = "";
    STATES.map((item) => {
      item.states.map((state) => {
        if (state.status) {
          states += `${state.value},`;
        }
      });
    });
    states = states.substring(0, states.length - 1);
    setselectedStates(states);
    countSelectedState > 0 &&
    thresholdValue &&
    !thresholdRangeError &&
    triggerDrop &&
    !triggerDropError
      ? setdisableRunButton(false)
      : setdisableRunButton(true);
    setshowStateSection(false);
    setshowSelectedStateLabel(true);
    setshowProbabilityTrigger(false);
  };

  const onChangeCheckbox = (e, state) => {
    setradioButtonValue("user-preference");
    setdisableRunButton(true);
    state.status = e.target.checked;
    setstateListArray([...STATES]);

    let selectedState = 0;
    STATES.map((item) => {
      item.states.map((state) => {
        if (state.status) {
          selectedState++;
        }
      });
    });
    setcountSelectedState(selectedState);

    setdisableDownload(true);
  };

  const handleThresholdInput = async (evt) => {
    if (
      evt.target.value.toString().indexOf(".") === 0 &&
      evt.target.value.toString().split(".")[1] &&
      evt.target.value.toString().split(".")[1].length > 2
    ) {
      evt.target.value = (Math.floor(evt.target.value * 100) / 100).toFixed(2);
      setthresholdValue(evt.target.value);
    }
    if (evt.target.value === "") {
      setdisableRunButton(true);
    } else if (evt.target.value > 50) {
      setthresholdRangeError(true);
      setdisableRunButton(true);
    } else if (evt.target.value < 0.01) {
      setthresholdRangeError(true);
      setdisableRunButton(true);
    } else {
      setthresholdRangeError(false);
    }
  };

  const handleTriggerDropInput = (evt) => {
    if (
      evt.target.value.toString().indexOf(".") === 0 &&
      evt.target.value.toString().split(".")[1] &&
      evt.target.value.toString().split(".")[1].length > 2
    ) {
      evt.target.value = (Math.floor(evt.target.value * 100) / 100).toFixed(2);
      setthresholdValue(evt.target.value);
    }
    if (evt.target.value === "") {
      setdisableRunButton(true);
    } else if (evt.target.value > 99) {
      settriggerDropError(true);
      setdisableRunButton(true);
    } else {
      settriggerDropError(false);
    }
  };

  const handleKeyDown = (evt) => {
    if (
      evt.key.toLowerCase() === "backspace" ||
      evt.key.toLowerCase() === "." ||
      evt.key.toLowerCase() === "tab"
    ) {
      return;
    }
    if (isNaN(evt.key)) {
      evt.preventDefault();
    }
    if (
      evt.target.value.toString().split(".")[1] &&
      evt.target.value.toString().split(".")[1].length > 1
    ) {
      evt.preventDefault();
    }
    if (
      !evt.target.value.toString().split(".")[1] &&
      evt.target.value.toString().length > 3
    ) {
      evt.preventDefault();
    }
  };

  const handleExclusionCheckBoxChange = (evt, name) => {
    const exclusionOptions = exclusionCheckBox;
    exclusionOptions[name] = evt.target.checked;
    setexclusionCheckBox({ ...exclusionOptions });

    setdisableDownload(true);
    setshowProbabilityTrigger(false);
  };

  const handleAnalysisOptions = (e) => {
    setanalysisType(e.target.value);

    if (e.target.value == "zip") {
      setZipCodeModalVisible(true);
    }

    setdisableDownload(true);
    setshowProbabilityTrigger(false);
  };

  const showAllSelectedState = () => {
    let states = ` ${selectedStates}`.slice(1);
    states = states.replace(/,/g, ", ");
    return states;
  };

  const getExclusionList = (shortText) => {
    let values = "";
    Object.keys(exclusionCheckBox)
      .filter((k) => exclusionCheckBox[k] === true)
      .map((item, index) => {
        let exclusionValues = "";
        if (shortText) {
          exclusionValues += EXCLUSION_LIST[item];
        } else {
          exclusionValues += item;
        }

        if (
          !(
            Number(
              Object.keys(exclusionCheckBox).filter(
                (k) => exclusionCheckBox[k] === true
              ).length
            ) -
              1 ==
            index
          )
        ) {
          exclusionValues += shortText ? "," : ", ";
        }
        values += exclusionValues;
      });
    return values;
  };

  const analyzeData = async () => {
    settotalRisk("");
    setreinsurerRisk("");
    setProbabilityForPML("");
    setaveragePayout("");
    setpercentageOfRisk("100");
    setpremiumPerOutlock("");
    setmaxSale("");
    setlowCapFactor("");
    sethighCapFactor("");
    if (analysisType == "square") {
      setnumberOfHotZones("0");
      sethotZonesTotalRisk("0");
    } else {
      setnumberOfHotZones("0");
      sethotZonesTotalRisk("0");
    }
    setdownloadURL(null);
    setdisableDownload(true);
    try {
      setdisableWhileLoading(true);
      setshowSpinner(true);
      const response = await connectionInstance.get(
        `${process.env.REACT_APP_API_URL}/probability/static_frequency_distribution`,
        {
          params: {
            geo_basis: analysisType,
            pricingOption: pricingValue,
            states: selectedStates,
            lowerEconomicPercentile:
              selectedProduct == "BudgetLock" ? lowerEconomicPercentile : null,
            upperEconomicPercentile:
              selectedProduct == "BudgetLock" ? upperEconomicPercentile : null,
          },
        }
      );

      if (response && response.data) {
        const xAxis = [];
        const yAxis = [];
        response.data.graph_data.map((item) => {
          if (analysisType == "square") {
            xAxis.push(item["Percentile of Squares"]);
          } else if (analysisType == "zip") {
            xAxis.push(item["Percentile of Zip Codes"]);
          } else {
            xAxis.push(item["Percentile of city_state"]);
          }
          yAxis.push(item["Percentage of economic activity"]);
        });

        setsquares([...xAxis]);
        seteconomicActivity([...yAxis]);
        setstatsFile(response.data.csv_file_name);

        const triggerRequestResponse = await connectionInstance.get(
          `${process.env.REACT_APP_API_URL}/probability/request`,
          {
            params: {
              states: selectedStates,
              trigger_threshold: Number(thresholdValue) * -1,
              triggerDrop: Number(triggerDrop) / 100,
              exclusions_list:
                Object.keys(exclusionCheckBox).filter(
                  (k) => exclusionCheckBox[k] === true
                ).length > 0
                  ? getExclusionList(true)
                  : null,
              // Object.keys(exclusionCheckBox).filter(
              //   (k) => exclusionCheckBox[k] === true
              // ).length > 0
              //   ? true
              //   : false,
              geo_basis: analysisType,
              pricingOption: pricingValue,
              num_ongoing_months: triggerDurationValue == "1 month" ? 1 : 2,
              lowerEconomicPercentile:
                selectedProduct == "BudgetLock"
                  ? lowerEconomicPercentile
                  : null,
              upperEconomicPercentile:
                selectedProduct == "BudgetLock"
                  ? upperEconomicPercentile
                  : null,
            },
          }
        );

        if (
          triggerRequestResponse &&
          triggerRequestResponse.data &&
          triggerRequestResponse.data.id
        ) {
          setrequestId(triggerRequestResponse.data.id);

          const interval = setInterval(async () => {
            const thresholdData = await getThresholdData(
              triggerRequestResponse.data.id,
              setshowLoading
            );
            if (thresholdData) {
              settriggerFileName(thresholdData.file_name);
              setdownloadURL(thresholdData.download_link);
              setnumberOfOutlockWorthySquare(
                thresholdData.outlock_worthy_entities
              );
              setdisableDownload(false);
              setshowSpinner(false);
              if (thresholdData.file_name) {
                clearInterval(interval);
                setdisableWhileLoading(false);
                clearTimeout(timeoutInfo);
                setfrequencyDistributionModalVisible(true);
              } else {
                setshowSpinner(true);
                setdisableDownload(true);
              }
            } else {
              clearInterval(interval);
              setshowProbabilityTrigger(false);
              setshowSpinner(false);
              setdisableRunButton(false);
              setdisableWhileLoading(false);
              clearTimeout(timeoutInfo);
              setdisableDownload(true);
            }
          }, intervalData.intervalDuration);
          setintervalData((prev) => ({
            ...prev,
            intervalValue: interval,
          }));
          let timeoutInfo = setTimeout(() => {
            clearInterval(interval);
            setdisableWhileLoading(false);
            setshowSpinner(false);
            message.error(
              "Sorry! We are unable to perform analysis now. Please try after some time."
            );
            setdisableRunButton(false);
            setTimeout(() => {
              setshowSpinner(false);
            }, 1000);
          }, 120000);
          setintervalData((prev) => ({
            ...prev,
            timeoutValue: timeoutInfo,
          }));
        }
      }

      if (Number(countSelectedState) !== 51) {
        let allStates = "";
        STATES.map((item) => {
          item.states.map((state) => {
            allStates += `${state.value},`;
          });
        });
        allStates = allStates.substring(0, allStates.length - 1);

        const response = await connectionInstance.get(
          `${process.env.REACT_APP_API_URL}/probability/request`,
          {
            params: {
              states: allStates,
              trigger_threshold: Number(thresholdValue) * -1,
              triggerDrop: Number(triggerDrop) / 100,
              exclusions_list:
                Object.keys(exclusionCheckBox).filter(
                  (k) => exclusionCheckBox[k] === true
                ).length > 0
                  ? getExclusionList(true)
                  : null,
              // Object.keys(exclusionCheckBox).filter(
              //   (k) => exclusionCheckBox[k] === true
              // ).length > 0
              //   ? true
              //   : false,
              geo_basis: analysisType,
              pricingOption: pricingValue,
              num_ongoing_months: triggerDurationValue == "1 month" ? 1 : 2,
              lowerEconomicPercentile:
                selectedProduct == "BudgetLock"
                  ? lowerEconomicPercentile
                  : null,
              upperEconomicPercentile:
                selectedProduct == "BudgetLock"
                  ? upperEconomicPercentile
                  : null,
            },
          }
        );

        if (response) {
          console.log("Response with all states received.", response);
        }
      }
    } catch (error) {
      setshowSpinner(false);
      setdisableWhileLoading(false);
      if (
        error &&
        error.response &&
        error.response.status &&
        error.response.status !== 401
      ) {
        if (error.response && error.response.data) {
          message.error(
            (typeof error.response.data.error_string === "string" &&
              error.response.data.error_string) ||
              (typeof error.response.data.message === "string" &&
                error.response.data.message) ||
              (typeof error.response.data.errorMsg === "string" &&
                error.response.data.errorMsg) ||
              (typeof error.response.data.error_message === "string" &&
                error.response.data.error_message) ||
              "Sorry! We are unable to perform analysis now. Please try after some time."
          );
        } else {
          message.error(
            "Sorry! We are unable to perform analysis now. Please try after some time."
          );
        }
      }
    }
  };

  const handleNumberOfPolicy = () => {
    if (Number(maxSale) > 1000000) {
      setmaxSale(1000000);
    }
    setPolicyNumberModalVisible(null);
  };

  const makeProduct = async () => {
    const exclusionArr = [];
    const states = [];
    Object.keys(exclusionCheckBox)
      .filter((k) => exclusionCheckBox[k] === true)
      .map((item) => {
        exclusionArr.push({ value: item });
      });

    stateListArray.map((item) => {
      item.states.map((state) => {
        if (state.status) {
          delete state.status;
          delete state.population;
          delete state.square;
          states.push(state);
        }
      });
    });

    const downloadJSON = PRODUCT_JSON(
      product.name,
      selectedProduct,
      triggerDrop.toString(),
      Number(thresholdValue),
      triggerDurationValue[0],
      states,
      Number(highCapFactor),
      Number(lowCapFactor),
      Number(averagePayout * maxSale),
      Number(maxSale),
      numberOfHotZones,
      Number(hotZonesTotalRisk),
      averagePayout,
      exclusionArr,
      moment().format("YYYY-MM-DDTHH:mm:ss"),
      moment().add(13, "months").endOf("month").format("YYYY-MM-DDTHH:mm:ss"),
      moment().add(2, "months").startOf("month").format("YYYY-MM-DDTHH:mm:ss")
    );
    const handle = await window.showSaveFilePicker({
      suggestedName: `${product.name}.json`,
      types: [
        {
          accept: { "application/json": [".json"] },
        },
      ],
    });
    const jsonString = JSON.stringify(downloadJSON, null, 4);
    const blob = new Blob([jsonString]);

    const writableStream = await handle.createWritable();
    await writableStream.write(blob);
    await writableStream.close();

    setTimeout(() => {
      message.success("Download Successful!");
      setMakeProductModalVisible(false);
    }, 1000);
  };

  const renderDataAnalysisInput = (readOnly) => (
    <DataAnalysisInput
      isReadOnly={readOnly}
      totalRisk={totalRisk}
      reinsurerRisk={reinsurerRisk}
      probabilityForPML={probabilityForPML}
      averagePayout={averagePayout}
      percentageOfRisk={percentageOfRisk}
      maxSale={maxSale}
      lowCapFactor={lowCapFactor}
      highCapFactor={highCapFactor}
      hotZonesTotalRisk={hotZonesTotalRisk}
      numberOfHotZones={numberOfHotZones}
      premiumPerOutlock={premiumPerOutlock}
      settotalRisk={settotalRisk}
      setreinsurerRisk={setreinsurerRisk}
      setProbabilityForPML={setProbabilityForPML}
      setaveragePayout={setaveragePayout}
      setpercentageOfRisk={setpercentageOfRisk}
      setmaxSale={setmaxSale}
      setlowCapFactor={setlowCapFactor}
      sethighCapFactor={sethighCapFactor}
      sethotZonesTotalRisk={sethotZonesTotalRisk}
      setnumberOfHotZones={setnumberOfHotZones}
      setpremiumPerOutlock={setpremiumPerOutlock}
      setPolicyNumberModalVisible={setPolicyNumberModalVisible}
      selectedProduct={selectedProduct}
      popover={popover}
      setpopover={setpopover}
      analysisType={analysisType}
      openNotification={openNotification}
      notification={notification}
    />
  );

  return (
    <div className={style.dashboardContainer}>
      {/* {contextHolder} */}
      <Spin
        spinning={showSpinner && !frequencyDistributionModalVisible}
        style={{ position: "fixed", top: "20%" }}
      >
        {/* <Modal
          visible={showProbabilityTrigger}
          className="triggerProbabilityModal"
          title={null}
          centered
          closable={false}
          // width={778}
          width={500}
          onOk={() => setshowProbabilityTrigger(false)}
          footer={[
            <Button
              key="back"
              type="primary"
              style={{ width: "67px" }}
              disabled={showLoading}
              onClick={() => setshowProbabilityTrigger(false)}
            >
              OK
            </Button>,
          ]}
        >
          <div className="probabilityTrigger">
            <Text className="probabilityTriggerTitle">
              Quick Analysis Result
            </Text>
            <div className="probabilityTriggerContent">
              {showLoading ? (
                <Components.Loading />
              ) : (
                <div style={{ fontWeight: 700 }}>
                  There is a{" "}
                  <span className="probabilityValue">{probabilityValue}%</span>{" "}
                  probability that you will pay out US${" "}
                  <span className="probabilityValue">
                    {expectedPayoutValue.toLocaleString()}
                  </span>{" "}
                  or less.
                </div>
              )}
            </div>
          </div>
        </Modal> */}

        <Modal
          visible={makeProductModalVisible}
          title={null}
          centered
          closable={false}
          width={426}
          footer={null}
        >
          <div>
            <div style={{ width: "100%" }}>
              <Components.FloatLabel
                labelName="Product Name"
                inputName="productName"
                valueText={product.name}
              >
                <Input
                  id="productName"
                  autoComplete="off"
                  size="large"
                  style={{ height: "45px", paddingTop: "9px" }}
                  value={product?.name}
                  onChange={(e) => {
                    const { value } = e.target;
                    setProduct((prev) => ({
                      ...prev,
                      name: value,
                    }));
                  }}
                />
              </Components.FloatLabel>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                key="back"
                type="link"
                style={{ padding: 0 }}
                onClick={() => {
                  setMakeProductModalVisible(false);
                  setProduct((prev) => ({
                    ...prev,
                    name: "",
                    internalName: "",
                    desc: "",
                  }));
                }}
              >
                CANCEL
              </Button>
              <Button
                type="primary"
                disabled={!product.name}
                onClick={() => makeProduct()}
              >
                MAKE PRODUCT
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          visible={!!policyNumberModalVisible}
          className={style.zipCodeModal}
          title={null}
          centered
          closable={false}
          width={426}
          onOk={() => handleNumberOfPolicy()}
          footer={null}
        >
          <div className={style.zipcodeModalText}>
            <p>{policyNumberModalVisible}</p>
            <div style={{ width: "100%", textAlign: "center" }}>
              <Button
                key="back"
                style={{ width: "67px" }}
                type="primary"
                onClick={() => handleNumberOfPolicy()}
              >
                OK
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          visible={zipCodeModalVisible}
          className={style.zipCodeModal}
          title={null}
          centered
          closable={false}
          width={426}
          onOk={() => setZipCodeModalVisible(false)}
          footer={null}
        >
          <div className={style.zipcodeModalText}>
            <strong>
              You have selected analysis by zip codes. Before you proceed,
              please be aware that:
            </strong>

            <p className={style.paragraph}>
              (a) We consider a zip code to be {selectedProduct}
              -worthy (i.e. you can sell
              {selectedProduct} within that zip code) as long as that zip code
              contains at least one
              {selectedProduct}
              -worthy square.
            </p>

            <p className={style.paragraph}>
              (b) The triggering of an {selectedProduct} that is sold in a zip
              code, is determined solely by the triggering of that{" "}
              {selectedProduct} as if it had been sold in an aggregate area
              consisting of all the
              {selectedProduct}
              -worthy squares in that zip code.
            </p>

            <p className={style.paragraph}>
              (c) The User Guide refers simply to the use of {selectedProduct}
              -worthy squares and does not refer to zip codes. This is because
              squares and their analysis underlies all work in this Underwriting
              Engine, regardless of whether squares are used directly, or
              whether they are associated with their containing zip codes. Thus,
              please feel free to use 'Zip Code' wherever you see 'Square' in
              the User Guide.
            </p>

            <div style={{ width: "100%", textAlign: "center" }}>
              <Button
                key="back"
                style={{ width: "67px" }}
                type="primary"
                onClick={() => setZipCodeModalVisible(false)}
              >
                OK
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          visible={!!confirmationModalVisible}
          className="confirmationModal"
          title={
            <div
              style={{
                color: "#90A7B9",
                fontSize: "24px",
                fontWeight: "700",
              }}
            >
              You have the following settings:
            </div>
          }
          centered
          closable={false}
          width={464}
          onOk={() => setconfirmationModalVisible(null)}
          footer={[
            <Button
              key="back"
              type="primary"
              style={{ width: "67px" }}
              onClick={() => setconfirmationModalVisible(null)}
            >
              OK
            </Button>,
          ]}
        >
          <div>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <strong>States:&nbsp;&nbsp;</strong>
              <div style={{ width: "318px" }}>{showAllSelectedState()}</div>
            </div>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <strong>Analysis by:&nbsp;&nbsp;</strong>
              <div>
                {analysisType === "zip"
                  ? "Zip Code"
                  : analysisType.charAt(0).toUpperCase() +
                    analysisType.slice(1)}
              </div>
            </div>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <strong style={{ whiteSpace: "nowrap" }}>
                Exclusions:&nbsp;&nbsp;
              </strong>
              <div>
                {Object.keys(exclusionCheckBox).filter(
                  (k) => exclusionCheckBox[k] === true
                ).length > 0
                  ? Object.keys(exclusionCheckBox)
                      .filter((k) => exclusionCheckBox[k] === true)
                      .map((values, index) => {
                        let exclusionValues = " ";
                        exclusionValues += values;
                        if (
                          !(
                            Number(
                              Object.keys(exclusionCheckBox).filter(
                                (k) => exclusionCheckBox[k] === true
                              ).length
                            ) -
                              1 ==
                            index
                          )
                        ) {
                          exclusionValues = `${exclusionValues},`;
                        }
                        return exclusionValues;
                        // }
                      })
                  : "NONE"}
              </div>
            </div>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <strong>Risk Threshold:&nbsp;&nbsp;</strong>
              <div>{thresholdValue}%</div>
            </div>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <strong>
                Drop&nbsp;
                {analysisType == "square"
                  ? "squares"
                  : analysisType == "zip"
                  ? "zip codes"
                  : "cities"}
                &nbsp;with highest trigger propensity:&nbsp;&nbsp;
              </strong>
              <div>{triggerDrop}%</div>
            </div>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <strong>Trigger Duration:&nbsp;&nbsp;</strong>
              <div>{triggerDurationValue}</div>
            </div>
            {selectedProduct == "BudgetLock" && (
              <>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <strong>Lower Economic Percentile:&nbsp;&nbsp;</strong>
                  <div>{lowerEconomicPercentile}</div>
                </div>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <strong>Upper Economic Percentile:&nbsp;&nbsp;</strong>
                  <div>{upperEconomicPercentile}</div>
                </div>
              </>
            )}
            <div style={{ marginBottom: "10px" }}>
              {/* {DeepAnalysisInput(true)} */}
              {renderDataAnalysisInput(true)}
            </div>
            <p>
              Your {confirmationModalVisible} analysis has started and the
              results are expected to be emailed to&nbsp;
              {user.email} within {confirmationModalVisible == "quick" ? 3 : 30}{" "}
              minutes or less.
            </p>
          </div>
        </Modal>

        <Modal
          visible={frequencyDistributionModalVisible}
          title={null}
          className="frequencyDistributionModal"
          centered
          closable={false}
          width="fit-content"
          maskClosable={false}
          footer={null}
        >
          <div style={{ display: "block" }}>
            <div style={{ display: "flex" }}>
              <div className="readonlySection">
                <div className="greyBorder">
                  <div className="modalTitle">
                    You have the following settings:
                  </div>
                  <div
                    className="optionSection"
                    style={
                      Number(countSelectedState) > 28 &&
                      selectedProduct == "BudgetLock"
                        ? { gap: "5px" }
                        : { gap: "10px" }
                    }
                  >
                    <div style={{ display: "flex" }}>
                      <strong>States:&nbsp;&nbsp;</strong>
                      <div style={{ width: "390px" }}>
                        {showAllSelectedState()}
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <strong>Analysis by:&nbsp;&nbsp;</strong>
                      <div>
                        {analysisType === "zip"
                          ? "Zip Code"
                          : analysisType.charAt(0).toUpperCase() +
                            analysisType.slice(1)}
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <strong>Exclusions:&nbsp;&nbsp;</strong>
                      <div>
                        {Object.keys(exclusionCheckBox).filter(
                          (k) => exclusionCheckBox[k] === true
                        ).length > 0
                          ? Object.keys(exclusionCheckBox)
                              .filter((k) => exclusionCheckBox[k] === true)
                              .map((values, index) => {
                                let exclusionValues = " ";
                                exclusionValues += values;
                                if (
                                  !(
                                    Number(
                                      Object.keys(exclusionCheckBox).filter(
                                        (k) => exclusionCheckBox[k] === true
                                      ).length
                                    ) -
                                      1 ==
                                    index
                                  )
                                ) {
                                  exclusionValues = `${exclusionValues},`;
                                }
                                return exclusionValues;
                              })
                          : "NONE"}
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <strong>Risk Threshold:&nbsp;&nbsp;</strong>
                      <div>{thresholdValue}%</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <strong>
                        Drop&nbsp;
                        {analysisType == "square"
                          ? "squares"
                          : analysisType == "zip"
                          ? "zip codes"
                          : "cities"}
                        &nbsp;with highest trigger propensity:&nbsp;&nbsp;
                      </strong>
                      <div>{triggerDrop}%</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <strong>Trigger Duration:&nbsp;&nbsp;</strong>
                      <div>{triggerDurationValue}</div>
                    </div>
                    {selectedProduct == "BudgetLock" && (
                      <>
                        <div style={{ display: "flex" }}>
                          <strong>
                            Lower Economic Percentile:&nbsp;&nbsp;
                          </strong>
                          <div>{lowerEconomicPercentile}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <strong>
                            Upper Economic Percentile:&nbsp;&nbsp;
                          </strong>
                          <div>{upperEconomicPercentile}</div>
                        </div>
                      </>
                    )}
                    <div style={{ display: "flex" }}>
                      <strong>
                        Number of {selectedProduct}
                        -worthy
                        {analysisType == "square"
                          ? " squares"
                          : analysisType == "zip"
                          ? " zip codes"
                          : " cities"}
                        :&nbsp;&nbsp;
                      </strong>
                      <div>{numberOfOutlockWorthySquare}</div>
                    </div>
                  </div>
                  <div className="imageSection">
                    {/* <div> */}
                    <Line
                      // height={"inherit"}
                      options={{
                        maintainAspectRatio: false,
                        title: {
                          display: true,
                          text: `Distribution of economic activity across${
                            analysisType == "square"
                              ? " Squares"
                              : analysisType == "zip"
                              ? " Zip Codes"
                              : " Cities"
                          }`,
                        },
                        responsive: true,
                        animation: {
                          duration: 2000,
                        },
                        legend: {
                          display: false,
                        },
                        tooltips: {
                          titleFontSize: 12,
                          bodyFontColor: "#969696",
                          borderColor: "#F55A70",
                          bodyFontSize: 14,
                          displayColors: false,
                        },
                        scales: {
                          xAxes: [
                            {
                              gridLines: {
                                display: false,
                              },
                              ticks: {
                                fontSize: 14,
                                fontColor: "#9B93BB",
                                fontWeight: 600,
                                maxRotation: 0,
                                minRotation: 0,
                                autoSkip: true,
                                maxTicksLimit: 6,
                              },
                              scaleLabel: {
                                display: true,
                                labelString: `% of ${
                                  analysisType == "square"
                                    ? "Squares"
                                    : analysisType == "zip"
                                    ? "Zip Codes"
                                    : "Cities"
                                }`,
                                fontColor: "#262626",
                                fontSize: 12,
                                padding: 0,
                                // fontWeight: 600,
                                // fontFamily: 'Montserrat',
                                // fontHeight: 15,
                                // fontStyle: 'bold',
                              },
                            },
                          ],
                          yAxes: [
                            {
                              id: "A",
                              display: true,
                              gridLines: {
                                display: true,
                              },
                              ticks: {
                                min: 0,
                                max: 100,
                                fontSize: 14,
                                fontColor: "#9B93BB",
                                fontWeight: 600,
                                maxTicksLimit: 10,
                              },
                              scaleLabel: {
                                display: true,
                                labelString: `% of max. economic activity per ${
                                  analysisType === "zip"
                                    ? "Zip code"
                                    : analysisType.charAt(0).toUpperCase() +
                                      analysisType.slice(1)
                                }`,
                                fontColor: "#262626",
                                fontSize: 12,
                                padding: 0,
                              },
                            },
                          ],
                        },
                      }}
                      data={{
                        labels: squares,
                        datasets: [
                          {
                            label: `% of max. economic activity per ${
                              analysisType === "zip"
                                ? "zip code"
                                : analysisType.charAt(0).toUpperCase() +
                                  analysisType.slice(1)
                            }`,
                            data: economicActivity,
                            fill: true,
                            backgroundColor: "#00a8e1",
                            borderColor: "#00a8e1",
                            pointRadius: 0,
                            pointHoverRadius: 0,
                            pointHitRadius: 20,
                          },
                        ],
                      }}
                    />
                    {/* </div> */}
                  </div>
                </div>
                <div className="modalFooter">
                  <div style={{ float: "left" }}>
                    <Button
                      key="back"
                      type="link"
                      style={{ float: "left", padding: 0 }}
                      disabled={disableWhileLoading}
                      onClick={() => {
                        setfrequencyDistributionModalVisible(false);
                        settotalRisk("");
                        setreinsurerRisk("");
                        setProbabilityForPML("");
                        setaveragePayout("");
                        setpercentageOfRisk("100");
                        setpremiumPerOutlock("");
                        setmaxSale("");
                        setlowCapFactor("");
                        sethighCapFactor("");
                        // setalertMessage(null);
                        notification.destroy();
                        if (analysisType == "square") {
                          setnumberOfHotZones("0");
                          sethotZonesTotalRisk("0");
                        } else {
                          setnumberOfHotZones("0");
                          sethotZonesTotalRisk("0");
                        }
                      }}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ float: "right" }}>
                    <Button
                      disabled={disableDownload}
                      className={style.downloadButton}
                      onClick={() => handleDownloadButtonClick()}
                      type="primary"
                      style={{ marginRight: "10px" }}
                    >
                      DATA DOWNLOAD
                    </Button>
                    <Popover
                      content={
                        <Text type="secondary">
                          Download the data that was used to arrive at the
                          trigger frequency, i.e. the actual triggers on a
                          {analysisType == "square"
                            ? " square-by-square"
                            : analysisType == "zip"
                            ? " zipcode-by-zipcode"
                            : " city-by-city"}
                          , month-by-month basis.
                        </Text>
                      }
                      title={
                        <div style={{ width: "100%", display: "flex" }}>
                          DATA DOWNLOAD
                          <span style={{ position: "absolute", right: "23px" }}>
                            <CloseOutlined
                              style={{ color: "rgba(0, 0, 0, 0.45)" }}
                              onClick={() =>
                                setpopover((prev) => ({
                                  ...prev,
                                  downloadData: false,
                                }))
                              }
                            />
                          </span>
                        </div>
                      }
                      trigger="click"
                      placement="topRight"
                      visible={popover.downloadData}
                      onVisibleChange={() =>
                        setpopover((prev) => ({
                          ...prev,
                          downloadData: !popover.downloadData,
                        }))
                      }
                    >
                      <QuestionCircleFilled
                        className={style.questionCircleIcon}
                      />
                    </Popover>
                  </div>
                </div>
              </div>

              <div className="inputSection">
                {/* <div className="alertMessageSection">{showAlert()}</div> */}
                <div className="modalBody">
                  {renderDataAnalysisInput(false)}
                </div>
                <div className="modalFooter">
                  <div className="footerItem">
                    <Button
                      key="submit"
                      type="primary"
                      style={{ marginRight: "10px" }}
                      disabled={disableFrequencyDistribution}
                      loading={showButtonSpinner.quickAnalysis}
                      onClick={() => handleFrequencyDistribution(true)}
                    >
                      QUICK ANALYSIS
                    </Button>
                    <Popover
                      content={
                        <Text type="secondary">
                          Given the settings entered by you, run the
                          Underwriting Engine in order to get a rough 'expected
                          value' for the frequency of triggering. This usually
                          takes less than a minute and the results are sent to
                          your login email address via e-mail.
                        </Text>
                      }
                      title={
                        <div style={{ width: "100%", display: "flex" }}>
                          QUICK ANALYSIS
                          <span style={{ position: "absolute", right: "23px" }}>
                            <CloseOutlined
                              style={{ color: "rgba(0, 0, 0, 0.45)" }}
                              onClick={() =>
                                setpopover((prev) => ({
                                  ...prev,
                                  quickAnalysis: false,
                                }))
                              }
                            />
                          </span>
                        </div>
                      }
                      trigger="click"
                      visible={popover.quickAnalysis}
                      onVisibleChange={() =>
                        setpopover((prev) => ({
                          ...prev,
                          quickAnalysis: !popover.quickAnalysis,
                        }))
                      }
                    >
                      <QuestionCircleFilled
                        className={style.questionCircleIcon}
                      />
                    </Popover>
                  </div>
                  <div className="footerItem">
                    <Button
                      key="submit"
                      type="primary"
                      style={{ marginRight: "10px" }}
                      disabled={disableFrequencyDistribution}
                      loading={showButtonSpinner.deepAnalysis}
                      onClick={() => handleFrequencyDistribution(false)}
                    >
                      DEEP ANALYSIS
                    </Button>
                    <Popover
                      content={
                        <Text type="secondary">
                          Given the settings entered by you, run the
                          Underwriting Engine in order to get a full frequency
                          distribution of triggers. This usually takes several
                          minutes and the results are sent to your login email
                          address via email, generally within 15 minutes of
                          clicking this button.
                        </Text>
                      }
                      title={
                        <div style={{ width: "100%", display: "flex" }}>
                          FULL ANALYSIS
                          <span style={{ position: "absolute", right: "23px" }}>
                            <CloseOutlined
                              style={{ color: "rgba(0, 0, 0, 0.45)" }}
                              onClick={() =>
                                setpopover((prev) => ({
                                  ...prev,
                                  deepAnalysis: false,
                                }))
                              }
                            />
                          </span>
                        </div>
                      }
                      trigger="click"
                      visible={popover.deepAnalysis}
                      onVisibleChange={() =>
                        setpopover((prev) => ({
                          ...prev,
                          deepAnalysis: !popover.deepAnalysis,
                        }))
                      }
                    >
                      <QuestionCircleFilled
                        className={style.questionCircleIcon}
                      />
                    </Popover>
                  </div>
                  <div className="footerItem">
                    <Button
                      type="primary"
                      style={{ marginRight: "10px" }}
                      disabled={disableFrequencyDistribution}
                      // loading={showButtonSpinner.deepAnalysis}
                      onClick={() => setMakeProductModalVisible(true)}
                    >
                      MAKE PRODUCT
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <div className={style.stateSection}>
          <Text className={style.title}>Select States</Text>
          <div className={style.stateListContent}>
            <div className={style.radioButtons}>
              <Radio.Group
                size="small"
                onChange={(e) => handleAnalysisRadioButtonChange(e)}
                value={radioButtonValue}
              >
                <Space direction="vertical">
                  {STATE_DISPLAY_TYPE.map((item) => (
                    <Radio
                      key={item.key}
                      value={item.value}
                      disabled={disableWhileLoading}
                    >
                      <Text
                        className={
                          radioButtonValue !== item.value
                            ? style.radioButtonText
                            : null
                        }
                      >
                        {item.display}
                      </Text>
                      <Text type="primary">
                        {radioButtonValue === item.value ? (
                          <Button
                            className={style.showStateButton}
                            type="link"
                            size="small"
                            onClick={() => setshowStateSection(true)}
                          >
                            {!showStateSection
                              ? `${
                                  showSelectedStateLabel &&
                                  countSelectedState > 0
                                    ? `Show selected ${countSelectedState}${" "}
                                ${countSelectedState > 1 ? "states" : "state"}`
                                    : "See selected states"
                                }`
                              : null}
                          </Button>
                        ) : null}
                      </Text>
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </div>
            {showStateSection ? (
              <div className={style.stateListSection}>
                <div style={{ display: "flow-root", marginTop: "20px" }}>
                  <Text className={style.chooseState}>
                    {radioButtonValue === "user-preference"
                      ? "Choose states"
                      : STATE_DISPLAY_TYPE.map((item) => {
                          if (item.value === radioButtonValue) {
                            return item.display;
                          }
                        })}
                  </Text>
                  <Button
                    className={style.selectAll}
                    type="link"
                    size="small"
                    onClick={() => selectAllStates(null, null)}
                    disabled={disableWhileLoading}
                  >
                    Select All
                  </Button>
                  <Button
                    className={style.selectAll}
                    type="link"
                    size="small"
                    onClick={() => clearAllStates(null, null)}
                    disabled={disableWhileLoading}
                  >
                    Clear All
                  </Button>
                </div>

                <div style={{ padding: "16px 0" }}>
                  <div className={style.separator} />
                </div>

                <div className={style.stateList}>
                  <Row gutter={16}>
                    {stateListArray.map((item, index) => (
                      <Col
                        style={{
                          flexBasis: "20%",
                          width: "20%",
                          display: "block",
                        }}
                        key={index}
                      >
                        <div>
                          <Text style={{ fontWeight: "700" }}>
                            {item.region}
                          </Text>
                          <Button
                            className={style.selectAll}
                            type="link"
                            size="small"
                            onClick={() =>
                              selectAllStates(item.region, item.states)
                            }
                            disabled={disableWhileLoading}
                          >
                            Select All
                          </Button>
                          <Button
                            className={style.selectAll}
                            type="link"
                            size="small"
                            onClick={() =>
                              clearAllStates(item.region, item.states)
                            }
                            disabled={disableWhileLoading}
                          >
                            Clear All
                          </Button>
                        </div>
                        <div style={{ display: "grid", marginTop: "7px" }}>
                          {item.states.map((state) => (
                            <Checkbox
                              disabled={disableWhileLoading}
                              style={{ marginLeft: 0, marginTop: "3px" }}
                              checked={state.status}
                              key={state.value}
                              onChange={(e) => onChangeCheckbox(e, state)}
                            >
                              <Text
                                className={
                                  !state.status ? style.checkBoxText : null
                                }
                              >
                                {state.display}
                                &nbsp;
                                <Input
                                  className={style.stateInput}
                                  disabled
                                  // disabled={disableWhileLoading}
                                  value="1.00"
                                />
                                {/* {state.display}&nbsp;({state.population},{" "}
                                {state.square}) */}
                              </Text>
                            </Checkbox>
                          ))}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>

                <div style={{ padding: "16px 0" }}>
                  <div className={style.separator} />
                </div>
                <div className={style.stateSectionFooter}>
                  <div className={style.stateDisplayExplanation}>
                    The number following each state is the 'price multiplier'.
                    If state-wise pricing is selected, the premium in the state
                    will be computed by multiplying the 'price multiplier' with
                    the premium at national level.
                    {/* The numbers in brackets following each state are the
                    population of the state in millions and the number of{" "}
                    {selectedProduct}-worthy squares in the state respectively. */}
                  </div>
                  <div style={{ position: "absolute", right: "0" }}>
                    <Text className={style.selectedStateCount}>
                      {countSelectedState}{" "}
                      {countSelectedState > 1 ? "States" : "State"} Selected
                    </Text>
                    <Button
                      className={style.saveState}
                      onClick={handleSaveSelectedState}
                      disabled={disableWhileLoading}
                    >
                      DONE
                    </Button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className={style.thresholdSection}>
          <Text className={style.thresholdTitle}>
            Select Risk Threshold & Exclusions
          </Text>
          <div className={style.thresholdContent}>
            <div className={style.riskThresholdSection}>
              {selectedProduct == "BudgetLock" && (
                <>
                  <Text className={style.riskThresholdTitle}>
                    Select cities between economic activity percentiles
                  </Text>
                  <div>
                    <Select
                      style={{
                        width: 66,
                        marginTop: "10px",
                        marginBottom: "15px",
                      }}
                      onChange={(val) => setlowerEconomicPercentile(val)}
                      value={lowerEconomicPercentile}
                    >
                      {new Array(11).fill().map((d, i) => (
                        <Option
                          value={i * 10}
                          disabled={
                            upperEconomicPercentile &&
                            i * 10 >= upperEconomicPercentile
                          }
                        >
                          {i * 10}
                        </Option>
                      ))}
                    </Select>
                    <span
                      style={{ margin: "0 20px" }}
                      className={style.radioButtonText}
                    >
                      and
                    </span>
                    <Select
                      style={{
                        width: 66,
                        marginTop: "10px",
                        marginBottom: "15px",
                      }}
                      onChange={(val) => setupperEconomicPercentile(val)}
                      value={upperEconomicPercentile}
                      disabled={Number(lowerEconomicPercentile) == 100}
                    >
                      {new Array(11).fill().map((d, i) => (
                        <Option
                          value={i * 10}
                          disabled={
                            lowerEconomicPercentile &&
                            i * 10 <= lowerEconomicPercentile
                          }
                        >
                          {i * 10}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </>
              )}
              {selectedProduct == "OutLock" && (
                <div style={{ marginBottom: "15px" }}>
                  <Radio.Group
                    onChange={(e) => handleAnalysisOptions(e)}
                    value={analysisType}
                    disabled
                  >
                    <>
                      <Radio
                        value="square"
                        // disabled={disableWhileLoading}
                        disabled
                      >
                        <Text
                          className={
                            analysisType !== "square"
                              ? style.radioButtonText
                              : null
                          }
                        >
                          Analysis by squares
                        </Text>
                      </Radio>
                      <Radio
                        value="zip"
                        // disabled={disableWhileLoading}
                        disabled
                      >
                        <Text
                          className={
                            analysisType !== "zip"
                              ? style.radioButtonText
                              : null
                          }
                        >
                          Analysis by zip codes
                        </Text>
                      </Radio>
                    </>
                  </Radio.Group>
                </div>
              )}
              <div style={{ marginBottom: "15px" }}>
                <Radio.Group
                  onChange={(e) => handlePricingRadioButtonChange(e)}
                  value={pricingValue}
                  disabled
                >
                  <Radio
                    value="national"
                    // disabled={disableWhileLoading}
                    disabled
                  >
                    <Text
                      className={
                        pricingValue !== "national"
                          ? style.radioButtonText
                          : null
                      }
                    >
                      National Pricing
                    </Text>
                  </Radio>
                  <Radio
                    value="state-wise"
                    // disabled={disableWhileLoading}
                    disabled
                  >
                    <Text
                      className={
                        pricingValue !== "state-wise"
                          ? style.radioButtonText
                          : null
                      }
                    >
                      State-wise Pricing
                    </Text>
                  </Radio>
                </Radio.Group>
              </div>
              {/* <div> */}
              <Text className={style.riskThresholdTitle}>Trigger Duration</Text>
              <div>
                <Select
                  placeholder="Trigger duration"
                  style={{
                    width: 120,
                    marginTop: "10px",
                    marginBottom: "15px",
                  }}
                  onChange={(val) => settriggerDurationValue(val)}
                  value={triggerDurationValue}
                  disabled={selectedProduct === "LoanProtect"}
                >
                  <Option value="1 month">1 month</Option>
                  <Option value="2 months">2 months</Option>
                </Select>
              </div>
              {/* </div> */}
              <Text className={style.riskThresholdTitle}>
                Threshold for any&nbsp;
                {analysisType == "square"
                  ? "square"
                  : analysisType == "zip"
                  ? "zip code"
                  : "city"}
                &apos;s performance
              </Text>
              <div className={style.riskThresholdContent}>
                <Input
                  className={
                    !thresholdRangeError
                      ? style.riskThresholdInput
                      : style.riskThresholdErrorInput
                  }
                  disabled={disableWhileLoading}
                  value={thresholdValue}
                  onChange={handleThresholdValueChange}
                  onKeyDown={(evt) => handleKeyDown(evt)}
                  onKeyUp={(evt) => handleThresholdInput(evt)}
                />
                <span className={style.riskThresholdPercentSymbol}>%</span>
                <div className={style.riskThresholdText}>
                  {/* Each square that suffers an economic downturn that is&nbsp;
                {thresholdValue}% below the reference values will be a candidate
                for a trigger. */}
                  <Tooltip
                    title={toolTipText}
                    trigger="click"
                    overlayStyle={{ whiteSpace: "pre-line" }}
                  >
                    <Button
                      style={{ padding: "0" }}
                      className={style.readMoreButton}
                      type="link"
                      size="small"
                    >
                      {/* Read more */}
                      <QuestionCircleFilled
                        className={style.questionCircleIcon}
                      />
                    </Button>
                  </Tooltip>
                </div>
              </div>
              <div style={{ display: "grid" }}>
                <div className={style.riskThresholdErrorSection}>
                  {thresholdRangeError ? (
                    <Text className={style.riskThresholdError}>
                      Threshold value should be greater than 0 and less than or
                      equal to 50
                    </Text>
                  ) : null}
                </div>

                {!(selectedProduct == "LoanProtect") && (
                  <>
                    <Text className={style.riskThresholdTitle}>
                      Drop&nbsp;
                      {analysisType == "square"
                        ? "squares"
                        : analysisType == "zip"
                        ? "zip codes"
                        : "cities"}
                      &nbsp;with highest trigger propensity&nbsp;&nbsp;
                    </Text>
                    <div className={style.riskThresholdContent}>
                      <Input
                        className={
                          !triggerDropError
                            ? style.riskThresholdInput
                            : style.riskThresholdErrorInput
                        }
                        // disabled={disableWhileLoading}
                        disabled
                        value={triggerDrop}
                        onChange={handleTriggerDropValueChange}
                        onKeyDown={(evt) => handleKeyDown(evt)}
                        onKeyUp={(evt) => handleTriggerDropInput(evt)}
                      />
                      <span className={style.riskThresholdPercentSymbol}>
                        %
                      </span>
                    </div>
                    <div className={style.riskThresholdErrorSection}>
                      {triggerDropError ? (
                        <Text className={style.riskThresholdError}>
                          Value should be less than or equal to 99
                        </Text>
                      ) : null}
                    </div>
                  </>
                )}

                <div className={style.exclusionCollapseSection}>
                  <Collapse
                    disabled
                    activeKey={activeExclusionPanel}
                    // onChange={() => {
                    //   if (activeExclusionPanel) {
                    //     setactiveExclusionPanel(null);
                    //   } else {
                    //     setactiveExclusionPanel(1);
                    //   }
                    // }}
                    expandIcon={({ isActive }) =>
                      isActive ? (
                        <MinusSquareFilled className={style.collapseIcon} />
                      ) : (
                        <PlusSquareFilled className={style.collapseIcon} />
                      )
                    }
                  >
                    <Panel header="Select Exclusions to Enable" key={1}>
                      {Object.keys(exclusionCheckBox).map((keyName, i) => (
                        <div className={style.exclusionCheckBoxSection}>
                          <Checkbox
                            size="small"
                            checked={exclusionCheckBox[keyName]}
                            // disabled={disableWhileLoading}
                            disabled
                            onChange={(evt) =>
                              handleExclusionCheckBoxChange(evt, keyName)
                            }
                          >
                            <Text
                              className={
                                exclusionCheckBox[keyName]
                                  ? style.active
                                  : style.inactive
                              }
                              style={{ lineHeight: "30px" }}
                            >
                              {keyName}
                            </Text>
                          </Checkbox>
                          <Popover
                            content={
                              <Text type="secondary">
                                {keyName === "WHO pandemic declaration"
                                  ? `As long as the World Health Organization has a pandemic warning in effect, no ${
                                      selectedProduct == "BudgetLock"
                                        ? "city"
                                        : "square or zip code"
                                    } shall trigger for any reason.`
                                  : `No ${
                                      selectedProduct == "BudgetLock"
                                        ? "city"
                                        : "square or zip code"
                                    } shall trigger for any reason so long as any US state which contains that ${
                                      selectedProduct == "BudgetLock"
                                        ? "city"
                                        : "square or zip code"
                                    }, is under a lockdown order imposed by State or Federal authorities.`}
                              </Text>
                            }
                            trigger="click"
                            visible={popover[EXCLUSION_LIST[keyName]]}
                            onVisibleChange={() => {
                              const popOverOptions = JSON.parse(
                                JSON.stringify(popover)
                              );
                              popOverOptions[EXCLUSION_LIST[keyName]] =
                                !popOverOptions[EXCLUSION_LIST[keyName]];
                              setpopover({ ...popOverOptions });
                            }}
                            placement="right"
                          >
                            <Button
                              type="link"
                              style={{
                                padding: 0,
                                height: "20px",
                                marginLeft: "auto",
                              }}
                              shape="circle"
                            >
                              <QuestionCircleFilled
                                className={style.questionCircleIcon}
                              />
                            </Button>
                          </Popover>
                        </div>
                      ))}
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={style.buttonSection}>
          <div className={style.resetRunSection}>
            <Button
              className={style.resetButton}
              type="link"
              onClick={() => handleResetButtonClick()}
              disabled={disableWhileLoading}
            >
              RESET
            </Button>
            <Popover
              content={
                <Text type="secondary">
                  Clear all settings and return the page to the state it was in,
                  when first entered.
                </Text>
              }
              title={
                <div style={{ width: "100%", display: "flex" }}>
                  RESET
                  <span style={{ position: "absolute", right: "23px" }}>
                    <CloseOutlined
                      style={{ color: "rgba(0, 0, 0, 0.45)" }}
                      onClick={() =>
                        setpopover((prev) => ({ ...prev, reset: false }))
                      }
                    />
                  </span>
                </div>
              }
              trigger="click"
              visible={popover.reset}
              onVisibleChange={() =>
                setpopover((prev) => ({
                  ...prev,
                  reset: !popover.reset,
                }))
              }
            >
              <Button
                type="link"
                style={{ padding: 0, verticalAlign: "text-top" }}
                shape="circle"
              >
                <QuestionCircleFilled className={style.questionCircleIcon} />
              </Button>
            </Popover>
            <span style={{ float: "right" }}>
              <Button
                className={style.runButton}
                type="primary"
                onClick={() => analyzeData()}
                disabled={disableRunButton}
              >
                IMPOSE CAPS
              </Button>
              <Popover
                content={
                  <Text type="secondary">
                    Assuming that you're satisfied with these settings, see the
                    distribution of economic activity in your selected sales
                    region and impose a set of caps to manage concentration
                    risk.
                  </Text>
                }
                title={
                  <div style={{ width: "100%", display: "flex" }}>
                    IMPOSE CAPS
                    <span style={{ position: "absolute", right: "23px" }}>
                      <CloseOutlined
                        style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        onClick={() =>
                          setpopover((prev) => ({ ...prev, run: false }))
                        }
                      />
                    </span>
                  </div>
                }
                trigger="click"
                visible={popover.run}
                onVisibleChange={() =>
                  setpopover((prev) => ({
                    ...prev,
                    run: !popover.run,
                  }))
                }
              >
                <Button type="link" style={{ padding: 0 }} shape="circle">
                  <QuestionCircleFilled className={style.questionCircleIcon} />
                </Button>
              </Popover>
            </span>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default Dashboard;
